import { differenceInHours } from "date-fns";
import { Appointment } from "../reclaim-api/Appointments";
import { AssistType, EventColor } from "../reclaim-api/EventMetaTypes";
import { Event } from "../reclaim-api/Events";

export interface MoreEvents {
  hasMore: true;
  on: Date;
  id: string;
  count: number;
}

// FIXME (IW): Refactor and figure out why `str.replaceAll` doesn't work in tests
export function eventToAppointment(event: Event): Appointment {
  // TODO (MA) this is SUPER dumb and should really be moved to the server
  const title = event.title?.replace("✅", "").replace("🛡", "").replace("🆓", "").replace("🔒", "");

  let formerTitleStatus: Appointment["formerTitleStatus"] = "none";

  // Matt, is this switch supposed to look at the first emoji of the title, or the whole title?
  // It doesn't look like it's doing what you meant for it to do.
  switch (event.title) {
    case "✅ ":
      formerTitleStatus = "check";
      break;
    case "🛡 ":
      formerTitleStatus = "shield";
      break;
    case "🆓 ":
      formerTitleStatus = "free";
      break;
    case "🔒 ":
      formerTitleStatus = "locked";
  }

  const normalizedChunks =
    event.chunks || event.timeChunks || (event.eventEnd && event.eventStart)
      ? (new Date(event.eventEnd).getTime() - new Date(event.eventStart).getTime()) / 900000
      : 0;

  return {
    id: event.key,
    title: event.sourceDetails?.title || title || "Untitled event",
    description: event.sourceDetails?.description || event.description || "",
    event: {
      ...event,
      chunks: normalizedChunks,
      timeChunks: normalizedChunks,
    },
    startDate: event.eventStart,
    endDate: event.eventEnd,
    habitId: event.assist?.dailyHabitId || undefined,
    taskId: event.assist?.taskId || undefined,
    formerTitleStatus,
    color: event.color || EventColor.None,
    hasMore: false,
    isAwaitingChange: false,
    pinned: !!event.assist?.pinned,
    allDay: differenceInHours(event.eventEnd, event.eventStart) >= 24,
  };
}

// FIXME (IW): ASAP!! This is crushing Safari
export function eventsToAppointments(events: Event[] = []): Appointment[] {
  const appointments = (events || []).reduce((acc: Appointment[], event: Event) => {
    if (!event) {
      return acc;
    }

    if (!acc.find((a) => a.event.eventId === event.eventId)) {
      // if the event in the loop has a ref to another event that is not yet inserted, shove that ahead in line...
      // TODO (ma) better managed by sorting events without a ref to always be first in the array
      if (
        (event.assist?.conferenceBuffer && !acc.find((a) => a.event.eventId === event.assist?.conferenceEventId)) ||
        (event.assist?.travelNewEventId && !acc.find((a) => a.event.eventId === event.assist?.travelNewEventId))
      ) {
        const assistEvent = events.find(
          (e) => (!!e && e.eventId === event.assist?.conferenceEventId) || e.eventId === event.assist?.travelNewEventId
        );
        if (assistEvent) {
          acc.push(eventToAppointment(assistEvent));
        }
      } else if (!event.assist?.conferenceBuffer && !event.assist?.travelNewEventId) {
        acc.push(eventToAppointment(event));
      }
    }

    if (event?.assist?.conferenceBuffer) {
      const sourceEvent = acc.find((a) => a.event?.eventId === event.assist?.conferenceEventId);
      if (sourceEvent) {
        sourceEvent.postAssistEvent = eventToAppointment(event);
        sourceEvent.endDate = event.eventEnd;
      }
    }

    if (event.assist?.travelNewEventId && event.assist.type === AssistType.PostTravel) {
      const sourceEvent = acc.find((a) => a.event?.eventId === event.assist?.travelNewEventId);
      if (sourceEvent) {
        sourceEvent.postAssistEvent = eventToAppointment(event);
        sourceEvent.endDate = event.eventEnd;
      }
    }

    if (event.assist?.travelNewEventId && event.assist.type === AssistType.PreTravel) {
      const sourceEvent = acc.find((a) => a.event.eventId === event.assist?.travelNewEventId);
      if (sourceEvent) {
        sourceEvent.preAssistEvent = eventToAppointment(event);
        sourceEvent.startDate = event.eventStart;
      }
    }

    const previousEvent = events.find(
      (e) =>
        !!e &&
        e.eventEnd > event.eventStart &&
        e.eventEnd < event.eventEnd &&
        (e.assist?.conferenceBuffer || event.assist?.travelNewEventId)
    );

    if (!!previousEvent && !event.assist?.conferenceBuffer && !event.assist?.travelNewEventId) {
      const sourceEvent = acc.find((a) => a.id === event.key);
      if (sourceEvent) {
        sourceEvent.previousEventHasAssist = true;
        sourceEvent.previousEvent = acc.find(
          (a) => a.id === previousEvent.key || a.postAssistEvent?.id === previousEvent.key
        );
      }
    }

    return acc;
  }, []);
  return appointments;
}
