import { BUILD_ID } from "../../analytics";
import { Override } from "../../types";
import { strToDate } from "../../utils/dates";
import { TransformDomain } from "../types";
import { ReclaimEdition } from "../Users";
import {
  JoinableTeam as JoinableTeamDto,
  MembershipRole as MembershipRoleDto,
  PartialTeam as PartialTeamDto,
  PartialTeamInvitation as PartialTeamInvitationDto,
  PartialTeamMember as PartialTeamMemberDto,
  ReclaimApi,
  ReclaimEdition as ReclaimEditionDto,
  RequestParams,
  TeamDomainView as TeamDomainViewDto,
  TeamInvitation as TeamInvitationDto,
  TeamInvoice as TeamInvoiceDto,
  TeamJoinResponse as TeamJoinResponseDto,
  TeamMember as TeamMemberDto,
  TeamMemberView as TeamMemberViewDto,
  TeamView as TeamViewDto,
} from "./client";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export type TeamInvoice = Override<TeamInvoiceDto, {}>;
export const STRIPE_SESSION_URI = `${API_BASE_URI}/team/current/subscription/session`;
export const STRIPE_NEW_SESSION_URI = `${API_BASE_URI}/team/create/subscription/session`;

export enum InvitationStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Deleted = "DELETED",
}

export enum TeamMemberViewStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
}

export enum MembershipRole {
  User = "USER",
  Admin = "ADMIN",
}

export const MembershipRoleLabel: Record<MembershipRole, string> = {
  ADMIN: "Admin",
  USER: "User",
};

export enum SubscriptionStatus {
  Trialing = "TRIALING",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum DomainSetting {
  InviteOnly = "INVITE_ONLY",
  Request = "REQUEST",
  Open = "OPEN",
  AutoDomainCapture = "AUTO_DOMAIN_CAPTURE",
}

export enum RequestedTeamStatus {
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export enum InviteSource {
  Default = "invite",
  CreateTeam = "create-team",
}

export type TeamMemberArchetype = Override<
  TeamMemberDto,
  {
    readonly edition: ReclaimEdition;
    readonly editionAfterTrial: ReclaimEdition;
    readonly role: MembershipRole;
  }
>;

export type TeamMember = Override<
  TeamMemberViewDto,
  {
    readonly edition: ReclaimEdition;
    readonly editionAfterTrial: ReclaimEdition;
    readonly status: TeamMemberViewStatus;
    readonly membershipRole: MembershipRole;
    readonly trialEnd?: Date;
    readonly requestedTeamStatus?: RequestedTeamStatus;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly name?: string;
    readonly editionUsage?: ReclaimEdition;
    readonly paidSeat?: boolean | null;
    readonly trialSeat?: boolean | null;
  }
>;

export type Team = Override<
  TeamViewDto,
  {
    readonly id?: number;

    userInviteLevel: ReclaimEdition;
    subscriptionStatus: SubscriptionStatus;
    paidProSeats: number;
    paidTeamSeats: number;
    proSeatsUsed: number;
    teamSeatsUsed: number;
    members: TeamMember[];
    domainSetting: DomainSetting;
  }
>;

export type PartialTeam = Override<
  PartialTeamDto,
  {
    userInviteLevel?: ReclaimEdition | null;
    domainSetting?: DomainSetting;
  }
>;

export type JoinableTeam = Override<
  JoinableTeamDto,
  {
    readonly domainSetting: DomainSetting;
  }
>;

export type TeamJoinResponse = Override<
  TeamJoinResponseDto,
  {
    role?: MembershipRole;
    editionAfterTrial?: ReclaimEdition;
  }
>;

export type PartialTeamMember = Override<
  PartialTeamMemberDto,
  {
    edition?: ReclaimEdition;
    role?: MembershipRole;
  }
>;

export type PartialTeamInvitation = Override<
  PartialTeamInvitationDto,
  {
    role: MembershipRole;
  }
>;

export type TeamInvitation = TeamInvitationDto;

export type TeamDomainView = TeamDomainViewDto;

export enum SubscriptionFrequency {
  Month = "MONTH",
  Year = "YEAR",
}

export type DesiredSubscription = { frequency: SubscriptionFrequency; seats: number; edition: ReclaimEdition };

export enum TeamRedirectAction {
  InviteAccepted = "accepted",
  PurchaseSuccess = "purchased",
  PurchaseCancelled = "purchaseCancelled",
}

export const dtoToTeamMember = (
  dto: TeamMemberViewDto & { firstName?: string; lastName?: string; name?: string }
): TeamMember => {
  const [dtoFirstName, ...otherNames] = (dto.name || "").split(" ");
  const dtoLastName = otherNames[otherNames.length - 1];

  return {
    ...dto,
    edition: ReclaimEdition.get(dto.edition) || ReclaimEdition.Free,
    editionAfterTrial: ReclaimEdition.get(dto.editionAfterTrial) || ReclaimEdition.Free,
    status: dto.status as unknown as TeamMemberViewStatus,
    membershipRole: dto.membershipRole as unknown as MembershipRole,
    requestedTeamStatus: !!dto.requestedTeamStatus
      ? (dto.requestedTeamStatus as unknown as RequestedTeamStatus)
      : undefined,
    trialEnd: strToDate(dto.trialEnd),
    firstName: dto.firstName || dtoFirstName,
    lastName: dto.lastName || dtoLastName,
  };
};

export const dtoToTeamMemberArchetype = (dto: TeamMemberDto): TeamMemberArchetype => {
  return {
    ...dto,
    edition: ReclaimEdition.get(dto.edition) || ReclaimEdition.Free,
    editionAfterTrial: ReclaimEdition.get(dto.editionAfterTrial) || ReclaimEdition.Free,
    role: dto.role as unknown as MembershipRole,
  };
};

export const dtoToTeam = (dto: TeamViewDto): Team => {
  return {
    ...dto,
    subscriptionStatus: dto.subscriptionStatus as unknown as SubscriptionStatus,
    paidProSeats: dto.paidProSeats || 0,
    paidTeamSeats: dto.paidTeamSeats || 0,
    proSeatsUsed: dto.proSeatsUsed || 0,
    teamSeatsUsed: dto.teamSeatsUsed || 0,
    userInviteLevel: ReclaimEdition.get(dto.userInviteLevel) || ReclaimEdition.None,
    members: dto.members?.map(dtoToTeamMember),
    domainSetting: dto.domainSetting as unknown as DomainSetting,
  };
};

export const dtoToJoinableTeams = (teams: JoinableTeamDto[]): JoinableTeam[] =>
  teams.map((team: JoinableTeamDto) => ({
    ...team,
    domainSetting: team.domainSetting as unknown as DomainSetting,
  }));

export const responsesToDto = (responses: TeamJoinResponse[]): TeamJoinResponseDto[] =>
  responses.map((r) => ({
    ...r,
    role: r.role as unknown as MembershipRoleDto,
    editionAfterTrial: r.editionAfterTrial as unknown as ReclaimEditionDto,
  }));

export const dtoToTeamInvoice = (dto: TeamInvoiceDto): TeamInvoice => {
  return {
    ...dto,
  };
};

export const invitesToDto = (invites: PartialTeamInvitation[]): PartialTeamInvitationDto[] => {
  return invites.map((i) => ({
    ...i,
    role: i.role as unknown as MembershipRoleDto,
  }));
};

export const partialTeamMemberToDto = (member: PartialTeamMember): PartialTeamMemberDto => ({
  ...member,
  role: member.role as unknown as MembershipRoleDto,
  edition: member.edition as unknown as ReclaimEditionDto,
});

export class TeamDomain extends TransformDomain<Team, TeamViewDto> {
  /**
   * The team domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing team module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "Team";
  cacheKey = "team";

  get = (): Promise<Team> => this.domainApi.team.getCurrentTeam().then(dtoToTeam);

  getMembers = (): Promise<TeamMember[]> =>
    this.domainApi.team.getMembers().then((response: TeamMemberViewDto[]) => response.map(dtoToTeamMember));

  getJoinableTeams = (): Promise<JoinableTeam[]> => this.domainApi.team.getJoinableTeams().then(dtoToJoinableTeams);

  respondToRequests = (responses: TeamJoinResponse[]): Promise<TeamMemberArchetype[]> =>
    this.domainApi.team.joinResponses(responsesToDto(responses)).then((r) => r.map(dtoToTeamMemberArchetype));

  requestToJoinTeam = (teamId: number) => this.domainApi.team.requestToJoinTeam(teamId);

  getRequests = (): Promise<TeamMemberArchetype[]> =>
    this.domainApi.team.getRequests().then((r) => r.map(dtoToTeamMemberArchetype));

  cancelRequestToJoinTeam = (teamId: number): Promise<void> => this.domainApi.team.cancelRequestToJoinTeam(teamId);

  getInviteableTeamMembers = (): Promise<TeamMemberArchetype[]> =>
    this.domainApi.team.getInviteableTeamMembers().then((r) => r.map(dtoToTeamMemberArchetype));

  listInvoices = (): Promise<TeamInvoice[]> => this.domainApi.team.listInvoices().then((r) => r.map(dtoToTeamInvoice));

  deleteMember = (userId: string) => this.domainApi.team.deleteMember({ userId });

  invite = (invites: PartialTeamInvitation[], source?: InviteSource): Promise<TeamInvitation[]> =>
    this.domainApi.team.createInvitation(invitesToDto(invites), { source });

  leaveTeam = () => this.domainApi.team.leaveTeam();

  patchTeam = (data: PartialTeam, params?: RequestParams) => this.domainApi.team.patchTeam(data, params);

  patchMember = (userId: string, data: PartialTeamMember, params?: RequestParams) =>
    this.domainApi.team.patchMember({ userId }, partialTeamMemberToDto(data), params);

  getInvitations = (): Promise<TeamInvitation[]> => this.domainApi.team.getInvitations();

  getDomains = (): Promise<TeamDomainView[]> => this.domainApi.team.getCurrentTeamDomains();

  acceptInvitation = (teamId: number, inviteId: string) =>
    this.domainApi.team.acceptInvitation(teamId, inviteId, { accept: true });

  deleteInvitation = (id: string) => this.domainApi.team.deleteInvitation(id);

  startSubscription = ({ frequency, seats, edition }: DesiredSubscription, newTab?: boolean) => {
    const baseRedirect = `${window.location.origin}/billing`;
    const url = new URL(STRIPE_NEW_SESSION_URI);

    url.search = new URLSearchParams({
      successUrl: `${baseRedirect}?action=${TeamRedirectAction.PurchaseSuccess}`,
      cancelUrl: `${baseRedirect}?action=${TeamRedirectAction.PurchaseCancelled}`,
      seats: seats.toString(),
      edition: edition.key,
      frequency,
    }).toString();

    if (!!newTab) {
      window.open(url.toString(), "billing_subscription");
    } else {
      window.location.href = url.toString();
    }
  };
}
